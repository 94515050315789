import React from 'react';
import { App, Debugger } from '@flexn/create';
import { LogBox } from 'react-native';
import { ThemeProvider } from '../config';
import Navigation from '../navigation';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from 'react-query';

LogBox.ignoreAllLogs();

const MyApp = () => {

    const queryClient = new QueryClient();

    return (
        <ThemeProvider>
            <QueryClientProvider client={queryClient}>
                <App style={{ flex: 1 }}>
                    <Navigation />
                    <Debugger />
                </App>
            </QueryClientProvider>
        </ThemeProvider>
    );

};

export default MyApp;