import React, { useContext, useRef } from 'react';
import { ScrollView as RNScrollView, useWindowDimensions, Dimensions } from 'react-native';
import { Text, View, ScrollView, Image, FlashList, ANIMATION_TYPES, StyleSheet, Pressable } from '@flexn/create';
import Screen from '../screen';
import { ROUTES, Ratio, ThemeContext } from '../../config';
import logo from "../../assets/logo.png";
import { useQuery } from 'react-query';

// screen size
const windowWidth: any = Dimensions.get('window').width;
const windowHeight: any = Dimensions.get('window').height;

const ScreenMovies = ({ navigation }: { navigation?: any }) => {

    const { height } = useWindowDimensions();
    const { theme, toggle } = useContext(ThemeContext);

    // Fetcher function
    const getResourceCategories = async () => {
        const res = await fetch('http://reb0rn.xyz/player_api.php?username=fons_69157137&password=07586648&action=get_vod_categories');
        return res.json();
    };

    // Using the hook
    const { data: categories, error: errcategories, isLoading: isloadingCategories } = useQuery('vod-categories', getResourceCategories);

    const [date, setDate] = React.useState(new Date());
    const [time, setTime] = React.useState("");

    const [category, setCategory] = React.useState("");

    React.useEffect(() => {
        var timer = setInterval(() => setDate(new Date()), 1000);
        return function cleanup() {
            clearInterval(timer)
        }
    });

    const onFocusCategory = (item: any) => {
        setCategory(item);
    }

    const renderCategoriesItem = ({ item, focusRepeatContext, index }: any) => {
        return (
            <Pressable
                style={[
                    styles.genreitem
                ]}
                focusRepeatContext={focusRepeatContext}
                onFocus={() => onFocusCategory(item.category_id)}
                focusOptions={{
                    animator: {
                        type: 'background',
                        focus: {
                            backgroundColor: '#128DD3'
                        },
                    }
                }}
            >
                <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", flex: 1 }}>
                    <Text style={{ color: "#ddd", fontSize: Dimensions.get("window").width / 75 }}>{item.category_name}</Text>
                </View>
            </Pressable>
        );
    };

    return (
        <Screen
            style={[theme.styles.screen, { minHeight: height, backgroundColor: "#111" }]}
            focusOptions={{ verticalWindowAlignment: 'bot-edge', focusKey: 'page' }}
        >
            <View style={{ flex: 1 }}>
                <View style={{ height: Dimensions.get("window").width / 20, flexDirection: "row", justifyContent: "center", alignItems: "center", marginLeft: 35, marginBottom: 20 }}>
                    <View style={{ marginTop: 30, marginLeft: 40 }}>
                        <Image source={logo} style={{ width: 300, height: 100 }} />
                    </View>
                    <View>
                        <Text style={{ color: "#ddd", marginLeft: 50, fontSize: Dimensions.get("window").width / 80, marginTop: 35 }}>{date.toDateString()} {date.toLocaleTimeString()}</Text>
                    </View>
                    <View style={{ flexGrow: 1 }} />
                </View>
                <View style={{ marginLeft: 20, marginBottom: 30 }}>
                    {categories &&
                        <FlashList
                            data={categories}
                            renderItem={renderCategoriesItem}
                            type="row"
                            estimatedItemSize={Dimensions.get("window").width / 10}
                            horizontal
                            showsHorizontalScrollIndicator={false}
                        />
                    }
                </View>
            </View>
        </Screen>
    )
}

const styles = StyleSheet.create({
    recyclerItem: {
        width: windowWidth / 9,
        height: windowWidth / 9,
        backgroundColor: "#222",
        alignItems: "center",
        justifyContent: 'center',
        margin: 15,
        borderRadius: 30
    },
    recyclerItemSettings: {
        width: windowWidth / 8,
        height: windowWidth / 12,
        backgroundColor: "#222",
        borderRadius: 30,
        alignItems: "center",
        marginLeft: 30,
        marginTop: 15,
        opacity: 0.6,
        justifyContent: 'center'
    },
    txtButton: {
        color: "#fff",
        fontSize: windowWidth / 120,
        width: 250,
        textAlign: "center"
    },
    txtButtonSettings: {
        color: "#fff",
        fontSize: windowWidth / 90,
        fontWeight: "bold",
        marginTop: 25,
        textTransform: "uppercase"
    },
    channelLogo: {
        width: windowWidth / 25,
        height: windowWidth / 25,
    },
    genreitem: {
        width: Dimensions.get("window").width / 5,
        height: Dimensions.get("window").width / 30,
        borderRadius: 15,
        marginRight: 30,
        backgroundColor: "#222",
        marginTop: 10
    }
});

export default ScreenMovies;