import React, { useContext, useRef } from 'react';
import { ScrollView as RNScrollView, useWindowDimensions, Dimensions } from 'react-native';
import { Text, View, ScrollView, Image, ImageBackground, ANIMATION_TYPES, StyleSheet, Pressable } from '@flexn/create';
import { Api } from '@rnv/renative';
import Icon from 'react-native-vector-icons/FontAwesome';
import { ROUTES, Ratio, ThemeContext } from '../../config';
import { useNavigate, useOpenURL } from '../../hooks';
import Screen from '../screen';
import packageJson from '../../../package.json';
import background from "../../assets/light_background.jpg"
import logo from "../../assets/logo.png";
import tvicon from "../../assets/tv.png";
import moviesicon from "../../assets/movies.png";
import seriesicon from "../../assets/series.png";
import searchicon from "../../assets/search.png";
import settingsicon from "../../assets/settings.png";
import playlisticon from "../../assets/playlist.png";

// screen size
const windowWidth: any = Dimensions.get('window').width;
const windowHeight: any = Dimensions.get('window').height;

const ScreenHome = ({ navigation }: { navigation?: any }) => {

    const { height } = useWindowDimensions();

    const navigate = useNavigate({ navigation });
    const openURL = useOpenURL();

    const { theme, toggle } = useContext(ThemeContext);

    const [date, setDate] = React.useState(new Date());
    const [time, setTime] = React.useState("");

    React.useEffect(() => {
        var timer = setInterval(() => setDate(new Date()), 1000);
        return function cleanup() {
            clearInterval(timer)
        }
    });

    return (
        <Screen
            style={[theme.styles.screen, { minHeight: height, backgroundColor: "#111" }]}
            focusOptions={{ verticalWindowAlignment: 'low-edge', focusKey: 'page' }}
        >
                <View style={{ height: Dimensions.get("window").width / 20, flexDirection: "row", justifyContent: "center", alignItems: "center", marginLeft: 35 }}>
                    <View style={{ marginTop: 30, marginLeft: 40 }}>
                        <Image source={logo} style={{ width: 300, height: 100 }} />
                    </View>
                    <View>
                        <Text style={{ color: "#ddd", marginLeft: 50, fontSize: Dimensions.get("window").width / 80, marginTop: 35 }}>{date.toDateString()} {date.toLocaleTimeString()}</Text>
                    </View>
                    <View style={{ flexGrow: 1 }} />
                </View>
                <View style={{ justifyContent: "center", marginLeft: 40, marginTop: 50 }}>
                    <View style={{ flexDirection: "row" }}>
                        <Pressable
                            onPress={() => navigation.navigate(ROUTES.CHANNEL_LIST_TV)}
                            style={styles.recyclerItem}
                            focusOptions={{
                                animator: {
                                    type: 'background',
                                    focus: {
                                        backgroundColor: '#128DD3'
                                    }
                                }
                            }}
                        >
                            <Image source={tvicon} style={{ width: 300, height: 300 }} />
                            <Text style={styles.txtButton}>Live TV</Text>
                        </Pressable>
                        <Pressable
                            style={styles.recyclerItem}
                            onPress={() => navigation.navigate(ROUTES.MOVIES)}
                            focusOptions={{
                                animator: {
                                    type: 'background',
                                    focus: {
                                        backgroundColor: '#128DD3'
                                    }
                                }
                            }}
                        >
                            <Image source={moviesicon} style={{ width: 300, height: 300 }} />
                            <Text style={styles.txtButton}>Movies</Text>
                        </Pressable>
                        <Pressable
                            onPress={() => navigation.navigate(ROUTES.SERIES)}
                            style={styles.recyclerItem}
                            focusOptions={{
                                animator: {
                                    type: 'background',
                                    focus: {
                                        backgroundColor: '#128DD3'
                                    }
                                }
                            }}
                        >
                            <Image source={seriesicon} style={{ width: 300, height: 300 }} />
                            <Text style={styles.txtButton}>Series</Text>
                        </Pressable>
                    </View>
                    <View style={{ flexDirection: "row", marginTop: 40, marginLeft: 10 }}>
                        <Pressable
                            onPress={() => navigation.navigate(ROUTES.SEARCH)}
                            style={styles.recyclerItemSettings}
                            focusOptions={{
                                animator: {
                                    type: 'background',
                                    focus: {
                                        backgroundColor: '#128DD3'
                                    }
                                }
                            }}
                        >
                            <Image source={searchicon} style={{ width: 80, height: 80 }} />
                            <Text style={styles.txtButtonSettings}>Search</Text>
                        </Pressable>
                        <Pressable
                            onPress={() => navigation.navigate(ROUTES.SETTINGS)}
                            style={styles.recyclerItemSettings}
                            focusOptions={{
                                animator: {
                                    type: 'background',
                                    focus: {
                                        backgroundColor: '#128DD3'
                                    }
                                }
                            }}
                        >
                            <Image source={settingsicon} style={{ width: 80, height: 80 }} />
                            <Text style={styles.txtButtonSettings}>Settings</Text>
                        </Pressable>
                        <Pressable
                            onPress={() => navigation.navigate(ROUTES.PLAYLIST)}
                            style={styles.recyclerItemSettings}
                            focusOptions={{
                                animator: {
                                    type: 'background',
                                    focus: {
                                        backgroundColor: '#128DD3'
                                    }
                                }
                            }}
                        >
                            <Image source={playlisticon} style={{ width: 80, height: 80 }} />
                            <Text style={styles.txtButtonSettings}>Playlists</Text>
                        </Pressable>
                    </View>
                </View>
        </Screen>
    );

};

const styles = StyleSheet.create({
    recyclerItem: {
        width: windowWidth / 4,
        height: windowWidth / 4,
        backgroundColor: "#222",
        borderRadius: 50,
        alignItems: "center",
        marginLeft: 30,
        marginTop: 15,
        opacity: 0.6,
        justifyContent: 'center'
    },
    recyclerItemSettings: {
        width: windowWidth / 8,
        height: windowWidth / 12,
        backgroundColor: "#222",
        borderRadius: 30,
        alignItems: "center",
        marginLeft: 30,
        marginTop: 15,
        opacity: 0.6,
        justifyContent: 'center'
    },
    txtButton: {
        color: "#fff",
        fontSize: windowWidth / 50,
        fontWeight: "bold",
        marginTop: 60,
        textTransform: "uppercase"
    },
    txtButtonSettings: {
        color: "#fff",
        fontSize: windowWidth / 80,
        fontWeight: "bold",
        marginTop: 25,
        textTransform: "uppercase"
    }
});

export default ScreenHome;