import React, { useContext, useRef } from 'react';
import { ScrollView as RNScrollView, useWindowDimensions, Dimensions, BackHandler } from 'react-native';
import { Text, View, CreateListRenderItemInfo, Image, ImageBackground, FlashList, StyleSheet, Pressable, useTVRemoteHandler } from '@flexn/create';
import Screen from '../screen';
import { ROUTES, Ratio, ThemeContext } from '../../config';
import background from "../../assets/light_background.jpg"
import tvicon from "../../assets/tv.png";
import moviesicon from "../../assets/movies.png";
import seriesicon from "../../assets/series.png";
import searchicon from "../../assets/search.png";
import settingsicon from "../../assets/settings.png";
import playlisticon from "../../assets/playlist.png";
import Osd from './Osd';
import { useQuery } from 'react-query';




// screen size
const windowWidth: any = Dimensions.get('window').width;
const windowHeight: any = Dimensions.get('window').height;

let OSDtimeout: any;

const ScreenLiveTv = ({ navigation, route }: { navigation?: any, route: any }) => {

    const { height } = useWindowDimensions();
    const { theme, toggle } = useContext(ThemeContext);

    const playerRef: any = useRef();

    // Fetcher function
    const getResource = async () => {
        const res = await fetch(`http://reb0rn.xyz/player_api.php?username=fons_69157137&password=07586648&action=get_live_streams&category_id=${route.params.channel.category_id}`);
        return res.json();
    };

    // Using the hook
    const { data: channels, error, isLoading } = useQuery('live-channels-categories', getResource);

    const [tvHandlerRemote, setTvHandlerRemote] = React.useState<any>();

    const [indexChannel, setIndexChannel] = React.useState(0);
    const [liveChannel, setLiveChannel] = React.useState<any>({});
    const [liveStream, setLiveStream] = React.useState("");

    const [openOSD, setOpenOSD] = React.useState<boolean>(false);
    const [osdActions, setOsdActions] = React.useState<boolean>(false);

    useTVRemoteHandler((remote: any) => {
        setTvHandlerRemote(remote);
    });

    React.useEffect(() => {

        if (tvHandlerRemote) {

            if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "up") {
                if (indexChannel < channels.length - 1) {
                    setIndexChannel((indexChannel: any) => indexChannel + 1);
                }
            }

            if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "down") {
                if (indexChannel > 0) {
                    setIndexChannel((indexChannel: any) => indexChannel - 1);
                }
            }

            if (!osdActions) {

                if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "left") {

                }

                if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "right") {

                }

                if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "select") {

                }

                if (tvHandlerRemote.eventKeyAction == "up" && (tvHandlerRemote.eventType == "left" || tvHandlerRemote.eventType == "right" || tvHandlerRemote.eventType == "select")) {

                }

            }

        }

    }, [tvHandlerRemote]);

    const goToChannel = (record: any) => {
        if (record) {
            setOpenOSD(true);
            setLiveChannel(record);
        }
    }

    const goBackAction = () => {
        navigation.goBack();
        return true;
    };

    const handleBack = () => {
        if (openOSD) {
            setOpenOSD(false);
        } else {
            goBackAction();
        }
        return true;
    }

    React.useEffect(() => {
        if (channels) {
            goToChannel(channels[indexChannel] ? channels[indexChannel] : channels[0]);
            setLiveStream(channels[indexChannel] ? "http://reb0rn.xyz/fons_69157137/07586648/" + channels[indexChannel].stream_id : channels[0].stream_id);
        }
    }, [indexChannel, channels]);

    React.useEffect(() => {
        if (channels) {
            if (route.params) {
                if (route.params.channel) {
                    const chn: any = channels;
                    const channelIndx = chn?.findIndex((d: any) => d.num == route.params.channel.num);
                    setIndexChannel(channelIndx);
                    goToChannel(channels[channelIndx]);
                }
            }
        }
    }, [route.params ? route.params.channel : null, channels]);


    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', handleBack);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', handleBack);
        };
    }, [openOSD]);

    React.useEffect(() => {

        OSDtimeout = setTimeout(() => {
            setOpenOSD(false);
            setOsdActions(false);
        }, 5000);

        return () => {
            clearTimeout(OSDtimeout);
        }

    }, [indexChannel, osdActions])

    return (
        <Screen
            style={[theme.styles.screen, { minHeight: height, backgroundColor: "#000" }]}
            focusOptions={{ verticalWindowAlignment: 'bot-edge', focusKey: 'page' }}
        >
            {openOSD && channels &&
                <Osd navigation={navigation} channel={liveChannel} ch_menu_category={route.params.category} />
            }
            <video width="100%" height="100%" autoPlay={true} src={liveStream} />
            {/* {liveStream !== "" ?
                <Video source={{ uri: liveStream }}
                    ref={ref => playerRef.current = ref}
                    useTextureView={false}
                    resizeMode="stretch"
                    style={styles.backgroundVideo}
                />
                :
                null
            } */}
        </Screen>
    );

}

const styles = StyleSheet.create({
    backgroundVideo: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
    },
    recyclerItem: {
        width: windowWidth / 9,
        height: windowWidth / 9,
        backgroundColor: "#222",
        alignItems: "center",
        justifyContent: 'center',
        margin: 15,
        borderRadius: 30
    },
    recyclerItemSettings: {
        width: windowWidth / 8,
        height: windowWidth / 12,
        backgroundColor: "#222",
        borderRadius: 30,
        alignItems: "center",
        marginLeft: 30,
        marginTop: 15,
        opacity: 0.6,
        justifyContent: 'center'
    },
    txtButton: {
        color: "#fff",
        fontSize: windowWidth / 90,
    },
    txtButtonSettings: {
        color: "#fff",
        fontSize: windowWidth / 90,
        fontWeight: "bold",
        marginTop: 25,
        textTransform: "uppercase"
    }
});

export default ScreenLiveTv;